import React, { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { useSentry } from "@heyhabito/sentry"
import { logLinkClick, logFormSubmit } from "../amplitude"
import {
  Body,
  BodyBold,
  borderRadii,
  breakpoints,
  Card,
  colours,
  column,
  GridRow,
  GBPInputQuestion,
  Headline,
  horizontal,
  MetaText,
  MoneyCoinIcon,
  SecondaryTwoLink,
  typographyStyles,
  useHasMounted,
  useMediaQuery,
  vertical,
} from "design-kit"

import { getSimplePlusQuote } from "../api"
import { SimplePlusQuote } from "../types"
import {
  MAX_PROPERTY_PRICE,
  formattedMaxPropertyPrice,
  formatPrice,
  isValidPropertyPrice,
} from "./helpers"
import { Slider } from "./Slider"

const Background = styled.section`
  background-color: #fface5;
  background-image: linear-gradient(#ffc1f9 0%, #fface5 100%);
`

const Container = styled.div<{ isNonMobile: boolean }>`
  background-color: ${colours.offBlack};
  border-radius: ${borderRadii.l};
  padding: ${vertical.xl} ${horizontal.s};
  ${props =>
    props.isNonMobile
      ? column({
          widthTablet: 6,
          widthDesktop: 12,
        })
      : ""}

  ${breakpoints.tablet`
    padding: ${vertical.xl} ${horizontal.l};
  `}

  ${breakpoints.desktop`
    display: flex;
    padding: ${vertical.xxl} 96px;
  `}
`

const SectionTitle = styled.h2`
  ${typographyStyles.statement3}
`

const Title = styled.h3`
  ${typographyStyles.bodyBold};
`

const EmphasisedRow = styled.div<{ bgColour: string }>`
  background-color: ${props => props.bgColour};
  display: flex;
  justify-content: space-between;
  border-radius: ${borderRadii.xs};
  padding: 16px;
  margin-top: ${vertical.xs};
`

const NonMobileGridRow: FC<{ isNonMobile: boolean }> = ({
  isNonMobile,
  children,
}) => {
  return isNonMobile ? (
    <GridRow>{children}</GridRow>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  )
}

const logValueChange = (v: number): void => {
  logFormSubmit("lets_talk_fees_quote_calculator", {
    propertyPrice: formatPrice(v),
  })
}

export const FeeCalculator: FC = () => {
  const hasMounted = useHasMounted()
  const isNonMobile = useMediaQuery(breakpoints.tablet.query)
  const { logToSentry } = useSentry()

  const firstRender = React.useRef(true)
  const [value, setValue] = React.useState<number>(200000)
  const [quote, setQuote] = React.useState<SimplePlusQuote | null>(null)

  React.useEffect(() => {
    // Debounces the request
    const requestTimeout = setTimeout(() => {
      if (isValidPropertyPrice(value)) {
        getSimplePlusQuote(value)
          .then(res => {
            setQuote(res)
          })
          .catch(err => {
            logToSentry(
              "Could not fetch simple Plus quote on the home-buying landing page",
              err
            )
          })
      }

      // little hack to prevent logging the property price on first render
      if (firstRender.current) {
        firstRender.current = false
      } else {
        logValueChange(value)
      }
    }, 500)

    return () => {
      clearTimeout(requestTimeout)
    }
  }, [value])

  return (
    <Background>
      {hasMounted && (
        <NonMobileGridRow isNonMobile={isNonMobile}>
          <Container isNonMobile={isNonMobile}>
            <div>
              <SectionTitle
                css={css`
                  color: ${colours.white};
                `}
              >
                Let’s talk fees{" "}
                <span
                  aria-hidden
                  css={css`
                    display: inline-flex;
                    height: 48px;
                    vertical-align: bottom;
                    width: 48px;

                    ${breakpoints.desktop`
                      height: 80px;
                      width: 80px;
                    `}

                    > svg {
                      height: auto;
                      margin: 0 auto;
                      width: 32px;

                      ${breakpoints.desktop`
                        width: 40px;
                      `}

                      ${breakpoints.desktop`
                        width: 66px;
                      `}
                    }
                  `}
                >
                  <MoneyCoinIcon color={colours.white} />
                </span>
              </SectionTitle>

              <Headline
                css={css`
                  color: ${colours.white};
                  margin-top: ${vertical.xxs};

                  ${breakpoints.desktop`
                    padding-right: ${horizontal.m};
                  `}
                `}
              >
                Our cost depends on your property price, but the fees start from
                around £2,000.
              </Headline>
            </div>

            <Card
              element="div"
              css={css`
                padding: ${vertical.l} ${horizontal.m};
                margin-top: ${vertical.l};

                ${breakpoints.tablet`
                  padding: ${vertical.l} ${horizontal.xl};
                `}

                ${breakpoints.desktop`
                  padding: ${vertical.l} ${horizontal.xl};
                  margin-top: 0;
                `}
              `}
            >
              <GBPInputQuestion
                error={
                  isValidPropertyPrice(value)
                    ? undefined
                    : value <= 0
                    ? "Please enter a value higher than 0"
                    : value > MAX_PROPERTY_PRICE
                    ? `Please enter a value lower than ${formattedMaxPropertyPrice}`
                    : undefined
                }
                onInput={v => setValue(Number(v))}
                value={value.toString()}
                title="Your property price"
              />
              <Slider
                ariaLabelForHandle={`Your property price in GBP, incremental. £10,000 to ${formattedMaxPropertyPrice}`}
                max={1500000}
                min={10000}
                onChange={v => setValue(v)}
                step={10000}
                value={value}
              />
              <MetaText
                aria-hidden
                css={css`
                  margin-top: ${vertical.xxs};
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  color: ${colours.greyScale.grey100};
                `}
              >
                <span>£10,000</span>
                <span>£1,500,000</span>
              </MetaText>

              <EmphasisedRow bgColour={colours.constructive.main}>
                <Body>Your price</Body>
                {quote && <BodyBold>{formatPrice(quote.quoteAmount)}</BodyBold>}
              </EmphasisedRow>
              <Title
                css={css`
                  margin-top: ${vertical.xs};
                `}
              >
                This includes:
              </Title>
              <ul>
                <li>
                  <EmphasisedRow bgColour={colours.greyScale.grey25}>
                    <BodyBold>All your conveyancing and legal work</BodyBold>
                    {quote && (
                      <Body>worth {formatPrice(quote.legalsWorth)}</Body>
                    )}
                  </EmphasisedRow>
                </li>
                <li>
                  <EmphasisedRow bgColour={colours.greyScale.grey25}>
                    <BodyBold>Property survey</BodyBold>
                    {quote && (
                      <Body>worth {formatPrice(quote.surveyWorth)}</Body>
                    )}
                  </EmphasisedRow>
                </li>
              </ul>
              <SecondaryTwoLink
                text="Lock in your quote"
                href="/how-can-we-help/new-mortgage"
                onClick={() => {
                  logLinkClick(
                    "lock_in_your_quote",
                    "/how-can-we-help/new-mortgage",
                    "lets_talk_fees"
                  )
                }}
                width={{
                  mobile: "full-width",
                  tablet: "content-width",
                  desktop: "content-width",
                }}
                css={css`
                  margin-top: ${vertical.s};
                `}
              />
            </Card>
          </Container>
        </NonMobileGridRow>
      )}
    </Background>
  )
}
