import { AmplitudeClient, Config } from "amplitude-js"
import { MYMVariant } from "./types"

/**
 * Initialise Amplitude; call once when the app boots. Doesn't make any HTTP
 * requests itself (unless `saveParamsReferrerOncePerSession` is set)
 */
export const init = async (
  client: AmplitudeClient,
  key: string,
  config: Config
): Promise<AmplitudeClient> => {
  return new Promise((resolve, reject) => {
    client.init(key, undefined, config, () => {
      return resolve(client)
    })
  })
}

interface CallbackData {
  code: number
  body: string
}

export const handleAmplitudeCallback =
  (resolve: (arg: CallbackData) => void, reject: (arg: CallbackData) => void) =>
  (code: number, body: string) => {
    // Code 0 is 'no event fired', which happens when Auth0 batches events for us
    if (code === 0 || (code >= 200 && code < 300)) {
      resolve({ code, body })
    } else {
      reject({ code, body })
    }
  }

/**
 * Identify the user to Amplitude via an API request. Should be called once,
 * usually when the app boots
 */
export const identify = async (
  client: AmplitudeClient,
  accountId: string,
  accountDomain: string | null
) =>
  new Promise<CallbackData>((resolve, reject) => {
    const identifier = new client.Identify()
    identifier.setOnce("account_id", accountId)
    identifier.setOnce("account_domain", accountDomain || "Unknown")
    client.identify(identifier, handleAmplitudeCallback(resolve, reject))
    client.setUserId(accountId)
  })

const logEvent = async (
  client: AmplitudeClient,
  eventName: string,
  properties: object
) =>
  new Promise((resolve, reject) => {
    client.logEvent(
      eventName,
      properties,
      handleAmplitudeCallback(resolve, reject)
    )
  })

export const logEventWithTimeout = async (
  client: AmplitudeClient,
  eventName: string,
  properties: object,
  timeout: number // milliseconds
): Promise<unknown> =>
  Promise.race([
    logEvent(client, eventName, properties),
    new Promise<void>(resolve => setTimeout(() => resolve(), timeout)),
  ])

/**
 * Log a page view event
 */
export const logViewedPage = (
  client: AmplitudeClient,
  pageName: string // The name of the page being viewed. Globally unique
) => logEvent(client, "viewed_page", { page_name: pageName })

/**
 * Log a button click event.
 */
export const logClickedButton = (
  client: AmplitudeClient,
  buttonName: string, // An identifier for the button. Unique within the page
  linkUrl: string, // Where the button leads, if it's a navigation CTA
  container: string, // The button's "interaction container" i.e. page area
  pageName: string, // The page containing the button. Globally unique
  extraInfo?: Record<string, unknown> // Extra useful information
): Promise<unknown> =>
  logEvent(client, "clicked_button", {
    ...extraInfo,
    button_name: buttonName,
    link_url: linkUrl,
    interaction_container: container,
    page_name: pageName,
  })

/**
 * Log a button click event with a timeout.
 */
export const logClickedButtonWithTimeout = (
  client: AmplitudeClient,
  buttonName: string, // An identifier for the button. Unique within the page
  linkUrl: string, // Where the button leads, if it's a navigation CTA
  container: string, // The button's "interaction container" i.e. page area
  pageName: string, // The page containing the button. Globally unique
  timeout: number, // milliseconds
  extraInfo?: Record<string, unknown> // Extra useful information
): Promise<unknown> =>
  Promise.race([
    logClickedButton(
      client,
      buttonName,
      linkUrl,
      container,
      pageName,
      extraInfo
    ),
    new Promise<void>(resolve => setTimeout(() => resolve(), timeout)),
  ])

/**
 * Log a form submission event.
 */
export const logSubmittedForm = (
  client: AmplitudeClient,
  formName: string, // An identifier for the form. Unique within the page
  formData: Record<string, unknown>, // The data submitted
  pageName: string, // The page containing the input. Globally unique
  extraInfo?: Record<string, unknown> // Extra useful information
): Promise<unknown> =>
  logEvent(client, "submitted_form", {
    ...extraInfo,
    form_data: formData,
    form_name: formName,
    page_name: pageName,
  })

/**
 * Log a MYM variant event
 */
export const logManageYourMortgageVariant = (
  client: AmplitudeClient,
  variant: MYMVariant
): Promise<unknown> =>
  logEvent(client, "manage_your_mortgage_variant", {
    variant,
  })

/**
 * Log an event that represents the number of times the Dashboard's Application
 * Space's Plus card is displayed to customers that are eligible for Plus but
 * still haven't signed up for it.
 */
export const logViewedPlusSignupCard = (
  client: AmplitudeClient,
  hasFoundSolicitor: "no" | "unknown" | "yes"
): Promise<unknown> =>
  logEvent(client, "viewed_plus_signup_card", {
    has_found_solicitor: hasFoundSolicitor,
  })
